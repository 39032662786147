import React from 'react';
import Cancellation from './Cancellation';

class Cancellations extends React.Component {
    render() {
        this.props.cancellations.shift();
        return (
            <div>
                {this.props.cancellations.map(cancellation => (
                    <Cancellation key={cancellation.date} cancellation={cancellation}></Cancellation>
                ))}
            </div>
        )
    }
}

export default Cancellations;